<template>
  <div class="box">
  <div class="all">
    <div class="right" v-if="!win">
      <div class="right_top">
        {{time}}
      </div>
      <div class="right_bottom">
        SECONDS
      </div>
    </div>
    <div class="right" v-if="win" :style="'background:' + '' + winner.color + ''">
      <div class="right_top_win">
        WINNER
      </div>
      <div class="right_bottom">
        {{winner.name}}
      </div>
     
    </div>
    <div class="left">
      <div class="left_card" v-for="item, index in list" :key="index">
        <div class="card_dian" :style="'background:' + '' + item.color + ''"></div>
        <div class="card_name">{{ item.name }}</div>
        <div class="card_num">
          {{item.num}}
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

// import CountTo from 'vue-count-to'
export default {
  name: 'PaGe2',
  components: {
    // CountTo
  },
  mounted() {
    // this.score=Number(this.$route.query.ster);
    // this.startVal=Number(this.$route.query.startVal);
    // setTimeout(this.valChange, 1000)
    window.getMessage = this.getMessage;//获取推送数据
    this.strs=this.$route.query.strs;
    this.time=this.$route.query.time;
    this.timer=setInterval(() => {
      this.time--;
      // console.log('计时---'+this.time);
      if(this.time<-10){
        this.$router.push({ path: '/page10'})
        clearInterval(this.timer);
      }
      }, 1000);

    for(var i=0;i<this.strs[1];i++){
      this.list.push({ name: '玩家'+(i+1), num: '', color: "#"+this.strs[i+2] });
    }
  },
  data() {
    return {
      timer:null,//计时器
      winner:null,
      win:false,
      startVal: 0,
      duration: 1500,
      time:90,
      strs:null,
      list: []
    }
  },
  methods: {
    valChange() {
      this.list.forEach((item, index) => {
        this.$refs['countTo' + index][0].start();
      })
    },
    getMessage(str) {
      console.log(str)
      const strs = str.split('-')
      if(strs[0]=='node'){
        this.list[strs[1]].num=strs[2]
      }
      if(strs[0]=='win'){
        clearInterval(this.timer);
        this.win=true;
        this.winner=this.list[strs[1]];
        setTimeout(() => {
          this.$router.push({ path: '/page10'})
        }, 10000);
      }
    }
  }
}
</script>

<style scoped>
.box{
  width: 100%;
  height: 100vh;
  font-family: 'bth';
  /* background-image: url("../assets/back.png"); */
  background: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.all {
  zoom: 0.5;
}
.all {
 
  display: flex;
  justify-content: center;
}

.right {
  width: 50%;
  text-align: center;
}

.right_top_win {
  font-size: 196px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 300px;
  line-height: 300px;
}
.right_top {
  font-size: 396px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 300px;
  line-height: 300px;
}

.right_bottom {
  font-size: 123px;
  font-weight: 400;
  color: #FFFFFF;
}

.left {
  width: 50%;
  margin-top: 147px;
}

.left_card {
  margin-left: 100px;
  display: flex;
  color: #FFFFFF;
  margin-bottom: 55px;
}

.card_dian {
  width: 95px;
  height: 95px;
  border: 7px solid #ffff;
  border-radius: 50%;
}

.card_name {
  width: 382px;
  height: 108px;
  line-height: 118px;
  font-size: 78px;
  color: #FFFFFF;
  margin-left: 68px;
}

.card_num {
  width: 51px;
  height: 45px;
  font-size: 78px;
  line-height: 118px;
  color: #FFFFFF;
}
</style>
