<template>
  <div class="box">
      <div class="top_left">
        <div style="    padding-left: 23px;
    padding-top: 4.8rem;font-size: 4rem;font-family: che; color: #e00070;">NO.{{ level }}</div>
      </div>
      <div  style="width: 100%;font: 12rem che;color: white;font-weight: 600;">
          <div style="width: 50%;float: left;text-align: center;">
            <div> {{ countdown }}</div>
            <div style="font-size: 3.3rem;">TARGET</div>
          </div>
        
            <div style="width: 50%;float: right;text-align: center">
              <div >
                {{ time }}s
              </div>
              <div style="font-size: 3.3rem;">
                COUNTDOWN
              </div>
            </div>
        </div>

      <div style="width: 100%;color: white;text-align: center;font: 1em che;font-size: 5.3rem;">
      <CountTo :startVal='startVal' :endVal='score' :duration='duration' :autoplay="false" ref="countTo"
                  style=" color: #e00070;font-weight: 800;letter-spacing: 4px;" />
                  <span style="font-weight: 200;font-size: 3rem;position: relative; top: -3px;">SCORE</span>
            </div>
      <div class="bottom">
        <div class="line" v-for="index in life" :key="index">
          <img v-if="index<=5" :src="require(`../assets/xiyatu/xin.png`)" width="100%" alt="">
          <!-- <img :src="require(`../assets/xin.png`)" alt="" style="width: 101px;"> -->
        </div>
        <div style="    position: absolute;right: 4rem;vertical-align: middle;font-size: 10rem;"> x {{life}}</div>
      </div>
    </div>
</template>

<script>
import CountTo from 'vue-count-to'
/* eslint-disable */
export default {
  components: {
    CountTo
  },
  name: 'PaGe2',
  data() {
    return {
      angle: 50,
      startVal: 0,
      duration: 1600,
      countdown: 10,
      mount: 10,
      oldtime: 100,
      time: 100,
      life: 5,
      score: 0,
      percentage: 1,
      title:'过关提示',
      name:'',
      level:1,
    }
  },
  computed: {
    // progress() {
    //   return (this.countdown / this.mount) * 100;
    // },
    // progresstime() {
    //   return (this.time / this.oldtime) * 100;
    // },
  },
  mounted() {
    window.getMessage = this.getMessage;//获取推送数据
    if (this.$route.query.str) {
      this.getMessage(this.$route.query.str)
      setTimeout(this.valChange, 1000)
    };
  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    drawPie() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const x = canvas.width / 2;
      const y = canvas.height / 2;
      const radius = 285;
      const startAngle = 0;
      const endAngle = 2 * Math.PI * this.percentage;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.arc(x, y, radius, startAngle, endAngle);
      ctx.closePath();
      ctx.fillStyle = 'rgb(35, 255, 0)';
      ctx.fill();
    },
    valChange() {
      this.$refs.countTo.start();
    },
    timer() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
          this.percentage = this.time / this.oldtime;
          // this.drawPie();
        }
       
      }, 1000);
    },
    getMessage(str) {
      console.log(str)
      const strs = str.split('-')
      //开始倒计时 游戏开始开始倒计时
      if (strs[0] == 'start') {
        this.time = strs[1];
        this.oldtime = strs[1];
        this.mount = strs[2];
        this.countdown = strs[2];
        this.timer();
        this.score = Number(localStorage.getItem('score'));
        this.startVal = Number(localStorage.getItem('score'));
      }
      //游戏扣减生命值
      if (strs[0] == 'life') {
        this.life = Number(strs[1]);
      }
      //游戏目标节点数，要换算成环形百分比
      if (strs[0] == 'node') {
        this.countdown = strs[1];
      }
      if (strs[0] == 'score') {
        this.score = strs[1];
      }
      //游戏结束，跳转结束页面 获取得分
      if (strs[0] == 'end') {
        // this.startVal = this.score
        // this.score = Number(strs[1]);
        if (strs[1]>0) {//成功页面page6
          // setTimeout(this.valChange, 1)
          this.$router.push({ path: '/page6', query: { startVal:this.score,score: Number(strs[1])} })

        } else {//失败页面page7
          localStorage.setItem('score', 0);
          // this.$router.push({ path: '/page6', query: { score: this.score } })
          this.$router.push({ path: '/page7', query: { score: this.score } })
        }
      }
      //跳转
      if (strs[0] == 'jump') {
        this.$router.push({ path: '/page3' })
      }
      if (strs[0] == 'title') {
        this.name = strs[1]
        this.level = strs[2]
        this.times = strs[3]
        this.title = strs[4]
      }
      if (strs[0] == 'persons') {
        this.$router.push({ path: '/page4', query: { strs: strs ,time:this.time} })
      }
    }
  }
}
</script>

<style scoped>

.contain {
  position: relative;
  width: 200px;
  height: 200px;
}

.main {
  height: 100%;
  background: black;
  border-radius: 100px;
}

.common {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
}

.mask2 {
  transform: rotate(45deg);
  transform-origin: left center;
  left: 100px;
  border-radius: 0 100px 100px 0;
  background: lightgreen;
}

.box {
  overflow: hidden;
  background-image: url("../assets/xiyatu/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.top_left {
  background-image: url("../assets/xiyatu/title.png");;
  text-align: center;
  font-weight: bold;
  width: 12.2rem;
  height: 9rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.center {
  display: flex;
  margin-top: 35px;
}

.center_left {
  width: 380px;
  height: 380px;
  margin-left: 230px;
  margin-top: 48px;
  position: relative;
}

.in {
  width: 440px;
  height: 400px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-28%, -28%);
  color: #fff;
  text-align: center;
  font-size: 138px;
  font-family: 'bth';
  line-height: 40px;
  padding-top: 40px;
  z-index: 99;
  background: black;
}

.inimg {
  margin-top: 67px;
  width: 150px;
}

.intime {
  font-size: 166px;
  margin-top: 126px
}

.progress-bar {
  transform: rotateX(180deg) rotate(90deg);
}

.center_right {
  margin-left: 240px;
  margin-top: 22px;
}

.mbdc {
  margin-left: 6px;
  height: 68px;
  margin-bottom: 24px;
}

.center_right_text {
  width: 830px;
  height: 205px;
  padding: 5px;
  border-radius: 30px;
  background: -webkit-linear-gradient(45deg, #05f43b, #0be7C1, #00dcdf);
  -moz-linear-gradient: (45deg, #05f43b, #0be7C1, #00dcdf);
  -ms-linear-gradient: (45deg, #05f43b, #0be7C1, #00dcdf);
}

.center_right_text_in {
  width: 770px;
  height: 165px;
  border-radius: 30px;
  background-color: #000;
  font-family: SourceHanSansCN, SourceHanSansCN;
  padding: 20px 30px;
  font-size: 100px;
  color: #fff;
  text-align: center;
  letter-spacing: 10px;
  font-weight: bold;
}

.center_right_bottom {
  display: flex;
  margin-top: 40px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-size: 90px;
  font-weight: bold;
}

.center_right_bottom_left {
  width: 400px;
  height: 189px;
  background: #00FFFC;
  border-radius: 20px 20px 20px 20px;
  padding-top: 56px;
  margin-right: 37px;
  text-align: center;
}

.center_right_bottom_right {
  width: 400px;
  height: 189px;
  background: #05F43B;
  border-radius: 20px 20px 20px 20px;
  padding-top: 56px;
  margin-right: 37px;
  text-align: center;
}

.bottom {
  width: 100%;
  display: flex;
  color: #e00070;
  font-size: 10rem;
  margin-left: 6.66rem;
}

.line {
  margin: 0.66rem;
  width: 142px;
  height: 114px;
}

.canvas {
}
</style>
