<template>
  <div class="main">
    <input ref="readerInput" type="text" tabindex="-1" @blur="handleBlur" style=" display:block;background: none;
    border: none;outline: none;position: fixed;z-index: 9999;width: 200px;height: 100px;" @keydown.enter="handleReaderInput">
    <div class="all">
      <div class="info" v-if="show" style="width: 100%;">
        <div class="left">
          <div class="left_top">
            <img src="../assets/xiyatu/left.png" @click="right()" alt="">
            <div v-if="tuan" class="top_tittle">TEAM MODE</div>
            <div v-if="!tuan" class="top_tittle">BATTLE MODE</div>
            <!-- <div v-if="!mod" class="top_tittle">团队模式</div>
            <div v-if="mod" class="top_tittle">计时模式{{ time }}</div> -->
            <img src="../assets/xiyatu/right.png" @click="right()" alt="">
          </div>
          <div class="gamelist" v-if="tuan">
            <div :class="active === index ? 'bottom_card_active' : 'bottom_card'" v-for="item, index in tuanGame"
              @click="changegame(index)" :key="index">
              {{ item.name }}
            </div>
          </div>
          <div class="gamelist" v-if="!tuan">
            <div :class="active === index ? 'bottom_card_active' : 'bottom_card'" v-for="item, index in jingGame"
              @click="changegame(index)" :key="index">
              {{ item.name }}
            </div>
          </div>
          <div class="back" @click="back">CANCEL</div>
        </div>
        <div class="right" :style="backgroundStyle">
          <div class="countdown">{{ countdown }}</div>
          <div class="bottom_input" type="text">{{ title }}
          </div>
          <div class="bottom_num" v-if="tuan">
            <div :class="numactive === index ? 'num_active' : 'num'" v-for="index in 10" @click="changenum(index)"
              :key="index">
              <span v-if="index <= levleLock">{{ index }}</span>
              <img v-else style="width: 60%;" src="../assets/锁.png">
            </div>
          </div>
          <div class="bottom_num" v-if="!tuan">
            <div :class="numactive === index ? 'num_active' : 'num'" v-for="index in 1" @click="changenum(index)"
              :key="index">
              <span>PK
              </span>
            </div>
          </div>
          <div class="start" @click="start">CONFIRM</div>
        </div>
        <div class="top">

          <img src="../assets/xiyatu/top.png"
            style="  width: 48.5%;   margin-left: 0%;  position: absolute;z-index: 100;" alt="">
          <img :src="gameImg"
            style="  z-index: 0; width: 46.4%;  height: 55%; margin-left: 1%; position: absolute;  top: 9%;" alt="">
        </div>
        <div class="bottom">
          <div class="user_box" style="margin-top: 80px;height: 180px; ">
            <div class="player" style=" overflow: hidden;height: 180px; margin: 5px" v-for="(item, index) in list"
              :key="index">
              <div>
                <span class="name"
                  style="margin-top: 152px;width: 120px;    text-align: center; top: unset; font-size: 23px;">{{ item.name
                  }}</span>
                <img class="avatar" style="border-radius: 50%; width: 74px;   position: absolute;  margin: 25px 25px;"
                  src="../assets/xiyatu/avatar.png">
                <img v-if="index == 0" class="img" src="../assets/xiyatu/player_1.png">
                <img v-if="index == 1" class="img" src="../assets/xiyatu/player_2.png">
                <img v-if="index == 2" class="img" src="../assets/xiyatu/player_3.png">
                <img v-if="index == 3" class="img" src="../assets/xiyatu/player_4.png">
                <img v-if="index == 4" class="img" src="../assets/xiyatu/player_5.png">
                <img v-if="index == 5" class="img" src="../assets/xiyatu/player_6.png">
                <img v-if="index == 6" class="img" src="../assets/xiyatu/player_7.png">
                <img v-if="index == 7" class="img" src="../assets/xiyatu/player_8.png">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="login" v-if="!show">
        <div class="right_top_title">
          <div class="title">
            Player Login
          </div>
        </div>
        <div class="user_box">
          <div v-for="(item, index) in list" :key="index">
            <div v-if="index == 0" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_1.png">

            </div>
            <div v-if="index == 1" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_2.png">
            </div>
            <div v-if="index == 2" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_3.png">
            </div>
            <div v-if="index == 3" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_4.png">
            </div>
            <div v-if="index == 4" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_5.png">
            </div>
            <div v-if="index == 5" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_6.png">
            </div>
            <div v-if="index == 6" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_7.png">
            </div>
            <div v-if="index == 7" class="player">
              <span class="time">{{ item.time }} min left</span>
              <span class="name">{{ item.name }}</span>
              <span class="score">Game Score</span>
              <span class="score_num">{{ item.points }}</span>
              <img class="avatar" src="../assets/xiyatu/avatar.png">
              <img style="" src="../assets/xiyatu/player_8.png">
            </div>


          </div>
          <div v-for="index in minPeople" :key="index">
            <div class="player" v-if="index > list.length">
              <img style="" src="../assets/xiyatu/player_0.png">
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;    margin-top: 220px;">
          <div class="reset" @click="reset">CANCEL</div>
          <div class="go" @click="login">CONFIRM</div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
/* eslint-disable */
export default {
  name: 'PaGe',
  data() {
    return {
      countdown: 60,// 初始倒计时60秒
      gamePic: require('@/assets/xiyatu/game.png'),
      backgroundUrl: require('@/assets/xiyatu/bg_right.png'),
      right1: require('@/assets/xiyatu/bg_right.png'),
      right2: require('@/assets/xiyatu/bg_right2.png'),
      tuan: true,
      show: false,
      devid: 'dakx6nrdrlp4260cvmdqbxew1lg12jvh',
      active: 0,
      numactive: 1,
      ganeindex: 0,
      gameList: [],
      glist: [],
      levleLock: 2,//默认锁关
      title: "",
      // list: [{ "id": 1, "points": 123123, "name": 123, time: 100 }, { "id": 1, "points": 123123, "name": 123, time: 100 }, { "id": 1, "points": 123123, "name": 123, time: 100 }, { "id": 1, "points": 123123, "name": 123, time: 100 }, { "id": 1, "points": 123123, "name": 123, time: 100 }, { "id": 1, "points": 123123, "name": 123, time: 100 }, { "id": 1, "points": 123123, "name": 123, time: 100 }, { "id": 1, "points": 123123, "name": 123, time: 100 }],
      list: [],

      users: [],
      time: -1,//计时时间
      mod: null,//模式
      timer: null,
      maxPeople: 5,
      minPeople: 2,
      intervalId: null
    }
  },
  computed: {
    tuanGame() {
      this.glist = this.gameList.filter(game => game.type === 0 && game.status == 1);
      return this.glist
    },
    jingGame() {
      this.glist = this.gameList.filter(game => game.type === 1 && game.status == 1);
      return this.glist
    },
    backgroundStyle() {
      return {
        backgroundImage: 'url(' + this.backgroundUrl + ')'
      };
    },
    gameImg() {
      if (this.glist.length == 0) {
        return "https://img.tuopan.ln.cn/2024/12/c2b7b53693d44141a11f32bc5fcc5fca.png"
      }
      let img = this.glist[this.active].img;
      if (img) {
        return "https://img.tuopan.ln.cn/" + img
      } else {//默认图片
        return "https://img.tuopan.ln.cn/2024/12/c2b7b53693d44141a11f32bc5fcc5fca.png"
      }

    }
  },
  mounted() {
    this.$refs.readerInput.setAttribute('tabindex', '0');
    this.$refs.readerInput.focus();
    window.cardMessage = this.cardMessage;//用户进入
    window.over = this.over;//游戏结束
    this.gameInfo();
  },
  beforeMount() {
    this.width = "屏幕宽度：" + window.screen.width
    this.height = "，屏幕高度：" + window.screen.height

  },
  methods: {
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId);
          this.back();
          this.reset();
        }
      }, 1000);
    },
    handleBlur() {
      // 先将输入框设置为可编辑
      this.$refs.readerInput.readOnly = true;
      // 延迟一段时间，确保点击操作完成后再设置焦点
      setTimeout(() => {
        this.$refs.readerInput.focus();
        // 先将输入框设置为可编辑
        this.$refs.readerInput.readOnly = false;
      }, 1);
    },
    handleReaderInput(event) {
      // 读取输入框的内容
      const data = event.target.value;
      // 处理读卡器输入的数据
      console.log('读卡器输入的数据:', data);
      // 清空输入框内容，以便下一次读取
      event.target.value = '';
      this.cardRead(data);
    },
    open() {
      this.show = !this.show
    },
    gameInfo() {
      this.devid = this.$route.query.devid
      // this.devid = this.devIds[this.ganeindex];
      //获取设备信息
      let that = this
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_equipment?imei=" + this.devid,//请求接口
        headers: { 'token': sessionStorage.getItem('token') }
      }).then(function (res) {
        that.configure = res.data.config
        that.gameList = res.data.gameList
        that.title = that.gameList[0].remark
        that.maxPeople = res.data.maxPeople
        that.minPeople = res.data.minPeople
        that.glist = that.gameList.filter(game => game.type === 0 && game.status == 1);
        if (that.configure.time) {
          that.mod = "time";
        }
      })
    },
    right() {
      if (this.tuan) {
        this.tuan = false
        this.backgroundUrl = this.right2;
        this.glist = this.gameList.filter(game => game.type === 1 && game.status == 1);
      } else {
        this.tuan = true
        this.backgroundUrl = this.right1;
        this.glist = this.gameList.filter(game => game.type === 0 && game.status == 1);
      }
      this.changegame(0);
      // this.gameInfo();
    },
    left() {

      // this.gameInfo();
    },
    changegame(i) {
      this.active = i

      if (this.glist[i]) {
        this.title = this.glist[i].remark
        this.getLock();
      }


    },

    changenum(i) {
      if (i <= this.levleLock) {
        this.numactive = i
      }
    },
    doTimer() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        }
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    back() {
      this.show = false;
      clearInterval(this.intervalId);
    },
    login() {
      if (this.users.length < this.minPeople) {
        this.$message({ message: 'Up to' + this.minPeople + ' players can start the game', type: 'error' });
        return false;
      }
      if (this.users.length > this.maxPeople) {
        this.$message({ message: 'At least' + this.minPeople + 'players can start the game', type: 'error' });
        return false;
      }
      this.show = true;
      this.countdown = 60;
      this.startCountdown();
    },
    start() {
      let game = this.glist[this.active];

      let game_name = game.name;
      // alert(game_name);
      let level = this.numactive.toString();
      let configure = this.configure
      let num = this.users.length;
      let game_configure = JSON.parse(game.config);
      let game_id = game_configure.id;
      let users = "";
      this.users.forEach(element => {
        users += element.id + ",";
      });
      document.location = `toapp://startGame?users=${users}&game_id=${game.gameId}&time=${this.time}&level=${level}&game_configure=${game.config}&game=${game_id}&num=${num}&configure=${configure}`
      // this.log({ "game_id": game_id, "game_name": game_name, "level": level, "game_status": "start", "is_finish": 0 });

    },//游戏结束	是否通过[0 1] over("1-1-1000")
    over(str) {
      const strs = str.split('-')
      let is_finish = strs[0];
      let level = strs[1];
      let point = strs[2];
      let game_id = this.gameList[this.active].game_id;
      let game_name = this.gameList[this.active].game_name;
      //如果过关同步积分
      if (is_finish) {
        this.point({ "game_id": game_id, "game_name": game_name, "level": level, point: point });
      }
      this.log({ "game_id": game_id, "game_name": game_name, "level": level, "game_status": "end", "is_finish": is_finish });
      this.reset();
      if (is_finish == '0') {
        document.location = `toapp://over`;
      }
    },//游戏日志
    log(data) {
      let that = this
      this.users.forEach(element => {
        that.$axios({
          method: "post",//指定请求方式
          url: "/api/set_game_log",//请求接口
          headers: { 'token': element.token },
          data: data
        })
      });
    },
    point(data) {
      let that = this
      this.users.forEach(element => {
        that.$axios({
          method: "post",//指定请求方式
          url: "/api/set_point_log",//请求接口
          headers: { 'token': element.token },
          data: data
        })
      });
    },
    getLock() {
      let level = 2; // 定义level变量，初始化为0
      const promises = this.users.map(element => {
        // 使用map来创建包含所有请求Promise的数组
        return this.$axios({
          method: "get",
          url: "/api/get_lock/" + element.id + "/" + JSON.parse(this.glist[this.active].config).id,
        }).then(res => {
          // console.log(res.data);
          if (res.data > level) {
            level = res.data; // 如果响应数据大于当前level，则更新level
          }
          if (element.isAdmin) {
            level = 10;
          }
        });
      });

      Promise.all(promises).then(() => {
        console.log(level);
        this.levleLock = level; // 所有请求完成后，设置levelLock为最终的level值
      })
    },
    getUser(userId) {
      let that = this
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_player/" + userId,//请求接口
      }).then(function (res) {
        let user = res.data;
        // user.token = token;
        if (user.isAdmin) {

          user.leftTime = 999
          document.location = `toapp://open`;
        }
        let time = user.leftTime;
        if (time <= 0) {
          that.$message({ message: '游戏时间不足', type: 'error' });
          return false;
        }
        that.users.push(user);


        that.$message({ message: 'Player join ' + user.name, type: 'success' });
        that.list.push({ name: user.name, time: Math.floor(time / 3600) + ":" + Math.floor(time / 60) % 60 + ":" + Math.floor(time % 60) });
        that.getLock();//拉取锁
        document.location = `toapp://join?num=${that.list.length}`

      })
    },
    reset() {
      this.active = 0
      this.numactive = 1
      this.ganeindex = 0
      this.list = [];
      this.users = [];
      document.location = `toapp://over`;
    },

    cardRead(msg) {//回车输入的卡号
      let that = this
      let repeat = false;
      if (msg.length < 10) {
        // that.$message({ message: 'card error!', type: 'error' });
        return false;
      }
      this.users.forEach(element => {
        if (element.cardNumber == msg) {
          repeat = true
          return false;
        }
      });
      if (repeat) {
        return false;
      }
      if (!msg && msg == '') {
        // that.$message({ message: 'card error!', type: 'error' });
        return false;
      }
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/login/" + msg,//请求接口
      }).then(function (res) {

        //刷卡登录
        if (that.users.length >= that.maxPeople) {
          that.$message({ message: 'The max player limit is' + that.maxPeople, type: 'error' });
          return false;
        }
        let user = res.data;
        if (!user.id) {
          // that.$message({ message: 'User error!', type: 'error' });
          return false
        }
        // user.token = token;
        if (user.isAdmin) {
          user.leftTime = 6000
          document.location = `toapp://open`;
        }
        // user.token = token;
        let time = user.leftTime;
        if (time <= 0) {
          that.$message({ message: 'Insufficient game time', type: 'error' });
          return false;
        }
        that.users.push(user);


        that.$message({ message: 'Player join ' + user.name, type: 'success' });

        that.list.push({ name: user.name, time: Math.floor(time / 60), points: user.points });
        that.getLock();//拉取锁
        document.location = `toapp://join?num=${that.list.length}`
        // if (token) {
        //   that.getUser(res.data.userId);

        // } else {
        //   that.$message({ message: '非法玩家', type: 'error' });
        // }
        // console.log(res);
      }).catch(function (error) {
        console.log(error);
        // that.$message({ message: error, type: 'error' });
      })
    }
  }
}


</script>

<style scoped>
.countdown {
  float: left;
  margin-left: 13rem;
  margin-top: 8rem;
  color: white;
  font-size: 4rem;
}

.main {
  font-family: 'che';
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../assets/xiyatu/bg.png");
  /* background-size: 100% 100%; */
  background-size: cover;
  /* 覆盖整个元素 */
  background-position: center;
  /* 图片居中显示 */
  background-repeat: no-repeat;
  /* 不重复图片 */

}

.gamelist {
  display: flex;
  width: 82%;
  margin-top: 10%;
  margin-left: 18%;
  align-items: center;
  height: 450px;
  flex-direction: column;
}

.bottom_card {
  width: 387px;
  color: #FFFFFF;
  text-align: center;
  font-size: 3rem;
  line-height: 4.5rem;
  font-weight: bold;
  font-family: che, che;
}

.bottom_card_active {
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
  line-height: 4.5rem;
  font-weight: bold;
  font-family: che, che;
  background-image: url("../assets/xiyatu/selected.png");
  background-size: 100% 100%;
  /* 覆盖整个元素 */
  background-position: center;
  /* 图片居中显示 */
  background-repeat: no-repeat;
}

.top_tittle {
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 800;
  font-family: che;
  color: #fff;
  border-radius: 10px;
  letter-spacing: 0px;
  margin-right: 20px;
  margin-left: 20px;
}

.main_2 {

  background-image: url("../assets/xiyatu/bg.png");
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* background-size: 100% 100%; */
  background-size: cover;
  /* 覆盖整个元素 */
  background-position: center;
  /* 图片居中显示 */
  background-repeat: no-repeat;
}

.left {
  display: block;
  float: left;
  height: 100%;
  width: 25.8%;
  background-image: url("../assets/xiyatu/bg_left.png");
}

.left_top {
  display: flex;
  width: 80%;
  margin-top: 23%;
  margin-left: 25%;
  align-items: center;
}

.right {
  height: 100%;
  width: 25.8%;
  float: right;
  /* background-image: url("../assets/xiyatu/bg_right.png"); */
}

.top {
  float: left;
  height: 65%;
  width: 48.4%;

}

.bottom {
  float: left;
  width: 48.4%;
  /* background-image: url("../assets/xiyatu/bottom.png"); */
  font-family: Arial, Helvetica, sans-serif;
}

.player {
  margin: 0 10px;
}

.time {
  position: absolute;
  top: 298px;
  width: 214px;
  display: block;
  color: #fff;
  font-weight: 600;
  font-size: 30px;
}

.score {
  position: absolute;
  top: 627px;
  width: 214px;
  display: block;
  color: #fff;
  font-size: 30px;
}

.name {
  position: absolute;
  top: 575px;
  width: 214px;
  display: block;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
}

.score_num {
  position: absolute;
  top: 685px;
  width: 214px;
  display: block;
  color: #5ceeff;
  font-weight: 400;
  font-size: 50px;
}

.title {
  margin-top: 110px;
  color: #e20079;
  text-align: center;
  font-size: 110px;
  font-weight: bold;
  font-family: che, che;

}

.user_box {
  display: flex;
  height: 380px;
  justify-content: center;
}

.all {
  margin: auto;
  display: flex;
  font-family: "che";
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 1920px;
}

/* .left {
  width: 43.5%;
  text-align: center;
} */



.login {
  text-align: center;
  width: 800px;
}

.right_top {
  width: 806px;
  height: 457px;
  border-radius: 10px;
  margin-top: 101px;
  margin-left: 111px;
  background-image: url("../assets/backgame2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 5px;
}

.right_top_title {
  display: flex;
  font-size: 37px;
  margin-left: 17px;
  margin: 0 auto;
  margin-bottom: 30px;
  justify-content: center;
}



.right_top_card_all {
  height: 520px;
  display: flex;
  overflow-y: auto;
  font-family: che, che;
  justify-content: center;
}

.right_top_card_all::-webkit-scrollbar {

  width: 0px;

  height: 0px;

}

.right_top_card {
  display: flex;
  justify-content: space-between;
  margin-left: 17px;
  padding: 0 50px;
}

.right_top_card_right {
  width: 327px;
  height: 68px;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 30px;
  border-radius: 10px;
  line-height: 70px;
  margin-bottom: 11px;
  font-size: 29px;
  font-weight: bold;
  color: #fff;
}

.right_top_card_left {
  width: 327px;
  height: 68px;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 17px;
  border-radius: 10px;
  line-height: 70px;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.right_top_card_right_hover {
  width: 327px;
  height: 68px;
  background: #00A9FA;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 30px;
  border-radius: 10px;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.right_top_card_left_hover {
  width: 327px;
  height: 68px;
  background: #00A9FA;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 17px;
  border-radius: 10px;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.reset {
  width: 208px;
  height: 68px;
  background-image: url("../assets/xiyatu/button_left.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  font-family: che, che;
}

.back {
  margin-top: 33%;
  margin-left: 34%;
  width: 50%;
  height: 68px;
  background-image: url("../assets/xiyatu/button_left.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  font-family: che, che;
}

.start {
  width: 50%;
  height: 68px;
  background-image: url("../assets/xiyatu/button_right.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px;
  letter-spacing: 10px;
  font-family: che, che;
  margin-top: 10%;
  margin-left: 16%;
}

.go {
  width: 208px;
  height: 68px;
  background-image: url("../assets/xiyatu/button_right.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 36px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px;
  letter-spacing: 10px;
  font-family: che, che;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  background-image: url("../assets/leftbackgrounddig.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: calc(50vh - 387px) auto;
  padding: 20px;
  width: 1318px;
  height: 734px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.bottom_input {
  text-align: left;
  font-family: 'che';
  font-size: 2.5rem;
  margin-left: 25px;
  margin-top: 50%;
  color: #fff;
  width: 71%;
  height: 370px;
  line-height: 37px;
  overflow: scroll;
}

.num {
  font-size: 40px;
  height: 60px;
  width: calc(20% - 10px);
  margin-right: 10px;
  margin-top: 10px;
  background-size: 100% 100%;
  background-image: url("../assets/xiyatu/level.png");
  color: #fff;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
}

.num_active {
  font-size: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  height: 60px;
  width: calc(20% - 10px);
  /* 假设每行三个子项，且有 10px 的间隔 */
  margin-right: 10px;
  background-image: url("../assets/xiyatu/level_on.png");

  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: 600;

}

.bottom_num {
  height: 13%;
  margin-top: 15px;
  display: flex;
  text-align: center;
  margin-left: 4%;
  width: 75%;
  color: #1bebfa;
  flex-wrap: wrap;
  align-content: flex-start;
}

.img {
  width: 120px;
  margin-top: -40px;
}

.avatar {
  border-radius: 50%;
  width: 140px;
  position: absolute;
  margin: 114px 40px;
}
</style>
