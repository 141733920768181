<template>
  <div class="box">
    <div class="all">
      <div class="right">
        <div class="right_top">
          <div class="line" v-for="index in life" :key="index"></div>
        </div>
        <div class="right_bottom">
          <div class="num">{{ time }}</div>
          <div class="tittle">SECONDS</div>
          <div class="fs">分数</div>
          <div class="num2">
            <CountTo :startVal='startVal' :endVal='score' :duration='duration' :autoplay="false" ref="countTo" />
          </div>
        </div>
      </div>
      <div class="left">
        <div class="left_top">
          {{ title }}
        </div>
        <div class="left_bottom">
          <div class="left_bottom_title" :style="{ color: color1 }">
            {{ answer1 }}
          </div>
          <div class="left_bottom_title" :style="{ color: color2 }">
            {{ answer2 }}
          </div>
          <div class="left_bottom_title" :style="{ color: color3 }">
            {{ answer3 }}
          </div>
          <div class="left_bottom_title" :style="{ color: color4 }">
            {{ answer4 }}
          </div>
          <div class="left_bottom_title" :style="{ color: color5 }">
            {{ answer5 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CountTo from 'vue-count-to'
export default {
  name: "PaGe2",
  components: {
    CountTo
  },
  data() {
    return {
      startVal: 0,
      duration: 1500,
      countdown: 10,
      mount: 10,
      time: 0,
      life: 5,
      score: 0,
      title: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      color1: "",
      color2: "",
      color3: "",
      color4: "",
      color5: "",
      obj: null
    };
  },
  computed: {
    progress() {
      return (this.countdown / this.mount) * 100;
    }
  },
  mounted() {
    window.getMessage = this.getMessage; //获取推送数据
    if (this.$route.query.str) {
      this.getMessage(this.$route.query.str); 
      setTimeout(this.valChange, 1000)
    }
  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    valChange() {
      this.$refs.countTo.start();
    },
    timer() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.time--;
        }
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    getMessage(str) {
      console.log(str);
      const strs = str.split("-");
      //开始倒计时 游戏开始开始倒计时
      if (strs[0] == "start") {
        this.time = strs[1];
        this.mount = strs[2];
        this.countdown = strs[2];
        let that = this;
        that.timer();
      }
      //游戏扣减生命值
      if (strs[0] == "life") {
        this.life = Number(strs[1]);
      }
      //游戏目标节点数，要换算成环形百分比
      if (strs[0] == "life") {
        this.life = Number(strs[1]);
      }
      if (strs[0] == "answers") {
        this.obj = JSON.parse(strs[1]);
        this.title = this.obj.title;
        this.answer1 = this.obj.answers[0];
        this.answer2 = this.obj.answers[1];
        this.answer3 = this.obj.answers[2];
        this.answer4 = this.obj.answers[3];
        this.answer5 = this.obj.answers[4];
      }
      if (strs[0] == "colors") {
        this.color1 = "#" + strs[1];
        this.color2 = "#" + strs[2];
        this.color3 = "#" + strs[3];
        this.color4 = "#" + strs[4];
        this.color5 = "#" + strs[5];
      }
      if (strs[0] == "score") {
        this.score = Number(strs[1]);
        setTimeout(this.valChange, 1)
      }
      //游戏结束，跳转结束页面 获取得分
      if (strs[0] == "end") {
        this.score = Number(strs[1]);
        if (this.score >0) {
          //成功页面page6
          this.$router.push({ path: '/page6', query: { startVal:this.score,score: Number(strs[1])} })
        } else {
          //失败页面page7
          this.$router.push({ path: "/page7", query: { score: this.score } });
        }
      }
    }
  }
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100vh;
  background: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
<style scoped>
.all {
  zoom: 1;
  width: 100%;
  height: 100vh;
  font-family: "pmzd";

  /* background: black; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.box {
  width: 100%;
  height: 100vh;
  background: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../assets/back.png");
}

.right_top {
  display: flex;
  width: 561px;
  padding-top: 36px;
}

.line {
  width: 70px;
  height: 164px;
  background: rgb(35, 255, 0);
  margin-left: 42px;
}

.right_bottom {
  width: 561px;
  margin-top: 17px;
}

.num {
  color: #ffffff;
  text-align: center;
  font-size: 193px;
  font-family: "bth";
  height: 180px;
  line-height: 180px;
  margin-top: 60px;
}

.tittle {
  color: #ffffff;
  text-align: center;
  font-family: "bth";
  letter-spacing: 5px;
  font-size: 60px;
}

.left_top {
  width: 712px;
  height: 170px;
  font-size: 62px;
  font-family: "bth";
  color: #ffffff;
  letter-spacing: 10px;
  line-height: 57px;
  padding-top: 35px;
  margin-left: 28px;
}

.left_bottom {
  margin-top: 46px;
}

.left_bottom_title {
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  letter-spacing: 8px;
  width: 54vw;
  height: 5vw;
  line-height: 5vw;
  margin-left: 0px;
  font-size: 48px;
  border: 3px solid #ffffff;
}

.fs {
  color: #ffffff;
  text-align: center;
  font-family: "bth";
  letter-spacing: 15px;
  font-size: 69px;
  margin-top: 20px;
}

.num2 {
  color: #ffffff;
  text-align: center;
  font-family: "bth";
  letter-spacing: 20px;
  font-size: 90px;
  line-height: 90px;
}
</style>
