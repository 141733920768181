<template>
  <div :class="tuan ? 'all' : 'all2'">
    <input ref="readerInput" type="text" tabindex="-1" @blur="handleBlur" style=" display:block;background: none;
    border: none;outline: none;position: fixed;" @keydown.enter="handleReaderInput">

    <div class="left">
      <div class="left_top" v-if="tuan">
        <img src="../assets/left.png" @click="right()" alt="">
        <div v-if="tuan" class="top_tittle">团队模式</div>
        <div v-if="!tuan" class="top_tittle2">竞技模式</div>
        <img src="../assets/right.png" @click="right()" alt="">

      </div>
      <div class="left_top" v-if="!tuan">
        <img src="../assets/left3.png" @click="right()" alt="">
        <div v-if="tuan" class="top_tittle">团队模式</div>
        <div v-if="!tuan" class="top_tittle2">竞技模式</div>
        <img src="../assets/right3.png" @click="right()" alt="">

      </div>
      <div class="left_bottom" v-if="tuan">
        <div class="bottom_title">
          GAME
        </div>
        <div class="bottom_allcard" v-if="tuan">
          <div :class="active === index ? 'bottom_card_active' : 'bottom_card'" v-for="item, index in tuanGame"
            @click="changegame(index)" :key="index">
            {{ item.name }}
          </div>
        </div>
        <div class="bottom_allcard" v-if="!tuan">
          <div :class="active === index ? 'bottom_card_active' : 'bottom_card'" v-for="item, index in jingGame"
            @click="changegame(index)" :key="index">
            {{ item.name }}
          </div>
        </div>
        <div class="gameall">
          <div class="bottom_input" type="text">{{ title }}
          </div>
          <div class="game">游戏说明</div>
        </div>
        <div class="bottom_level">
          LEVEL
        </div>
        <div class="bottom_num">
          <div :class="numactive === index ? 'num_active' : 'num'" v-for="index in 10" @click="changenum(index)"
            :key="index">
            <span v-if="index <= levleLock">{{ index }}</span>
            <img v-else style="width: 60%;" src="../assets/锁.png">
          </div>
        </div>
      </div>

      <div class="left_bottom2" v-if="!tuan">
        <div class="bottom_title">
          GAME
        </div>
        <div class="bottom_allcard" style="flex-direction: column;justify-content: center;" v-if="!tuan">
          <div :class="active === index ? 'bottom_card_active2' : 'bottom_card2'" v-for="item, index in jingGame"
            @click="changegame(index)" :key="index">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="right" v-if="tuan">
      <div class="right_top">
        <div class="right_top_title">
          <div class="title">
            <div>Player</div>
            <div>Name</div>
          </div>
          <div class="title">
            <div>Time</div>
            <div>Left</div>
          </div>
        </div>
        <div class="right_top_card_all" id="users">
          <div class="right_top_card" v-for="(item, index) in list" :key="index">
            <div :class="item.name ? 'right_top_card_right_hover' : 'right_top_card_right'">
              {{ item.name }}
            </div>
            <div :class="item.time ? 'right_top_card_left_hover' : 'right_top_card_left'" class="">
              {{ item.time }}
            </div>
          </div>
        </div>
      </div>
      <div class="reset" @click="reset">
        RESET
      </div>
      <div class="start" @click="start">
        START
      </div>
    </div>
    <div v-if="!tuan" style="width: 573px;"></div>
    <div class="right2" v-if="!tuan">
      <div class="right_top2">
        <div class="right_top_title">
          <div class="title">
            <div>Rank</div>
          </div>
          <div class="title">
            <div>PlayerName</div>
          </div>
          <div class="title">
            <div>Time Left</div>
          </div>
        </div>
        <div class="right_top_card_all" id="users">
          <div class="right_top_card" v-for="(item, index) in list" :key="index">
            <div class="right_top_card_right_hover" style="background-color: rgba(145, 42, 5, 0.5);;border: 4px solid #e9774e;">
              {{ index+1 }}
            </div>
            <div :class="'right_top_card_right_hover'+index"  >
              {{ item.name }}
            </div>
            <div class="right_top_card_right_hover"  style="background-color: #912a05;border: 4px solid #e9774e;width: 200px;">
              {{ item.time }}
            </div>
          </div>
        </div>
      </div>
      <div class="gameall" style="width: 50%;float: left;">
        <div class="bottom_input2" style="border: 4px solid #dc450e;border-radius: 10px;" type="text">{{ title }}
        </div>
        <div class="game">游戏说明</div>
      </div>
      <div style="width: 50%;float: right;">
        <div class="reset2" @click="reset">
        </div>
        <div class="start2" @click="start">
        </div>
      </div>

    </div>
  </div>


</template>

<script>
/* eslint-disable */
export default {
  name: 'PaGe',
  data() {
    return {
      countdown: 60,// 初始倒计时60秒
      gamePic: require('@/assets/nanjing/game.png'),
      backgroundUrl: require('@/assets/nanjing/bg_right.png'),
      right1: require('@/assets/nanjing/bg_right.png'),
      right2: require('@/assets/nanjing/bg_right2.png'),
      tuan: true,
      show: false,
      ifdebug: '',
      devid: 'dakx6nrdrlp4260cvmdqbxew1lg12jvh',
      active: 0,
      numactive: 1,
      ganeindex: 0,
      gameList: [],
      glist: [],
      levleLock: 4,//默认锁关
      title: "",
      // list:[],
      list: [],
      users: [],
      time: -1,//计时剩余时间
      mod: null,//模式
      timer: null,
      maxPeople: 5,
      minPeople: 2,
      intervalId: null
    }
  },
  computed: {
    tuanGame() {
      this.glist = this.gameList.filter(game => game.type === 0 && game.status == 1);
      return this.glist
    },
    jingGame() {
      this.glist = this.gameList.filter(game => game.type === 1 && game.status == 1);
      return this.glist
    },
    backgroundStyle() {
      return {
        backgroundImage: 'url(' + this.backgroundUrl + ')'
      };
    },
    gameImg() {
      if (this.glist.length == 0) {
        return "https://img.tuopan.ln.cn/2024/12/c2b7b53693d44141a11f32bc5fcc5fca.png"
      }
      let img = this.glist[this.active].img;
      if (img) {
        return "https://img.tuopan.ln.cn/" + img
      } else {//默认图片
        return "https://img.tuopan.ln.cn/2024/12/c2b7b53693d44141a11f32bc5fcc5fca.png"
      }

    }
  },
  mounted() {
    this.$refs.readerInput.setAttribute('tabindex', '0');
    this.$refs.readerInput.focus();
    window.cardMessage = this.cardMessage;//用户进入
    window.over = this.over;//游戏结束
    this.gameInfo();
  },
  beforeMount() {
    this.width = "屏幕宽度：" + window.screen.width
    this.height = "，屏幕高度：" + window.screen.height

  },
  methods: {
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId);
          this.back();
          this.reset();
        }
      }, 1000);
    },
    handleBlur() {
      // 先将输入框设置为可编辑
      this.$refs.readerInput.readOnly = true;
      // 延迟一段时间，确保点击操作完成后再设置焦点
      setTimeout(() => {
        this.$refs.readerInput.focus();
        // 先将输入框设置为可编辑
        this.$refs.readerInput.readOnly = false;
      }, 1);
    },
    handleReaderInput(event) {
      // 读取输入框的内容
      const data = event.target.value;
      // 处理读卡器输入的数据
      console.log('读卡器输入的数据:', data);
      // 清空输入框内容，以便下一次读取
      event.target.value = '';
      this.cardRead(data);
    },
    open() {
      this.show = !this.show
    },
    gameInfo() {
      this.devid = this.$route.query.devid
      this.ifdebug = this.$route.query.ifdebug
      // this.devid = this.devIds[this.ganeindex];
      //获取设备信息
      let that = this
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_equipment?imei=" + this.devid,//请求接口
        headers: { 'token': sessionStorage.getItem('token') }
      }).then(function (res) {
        that.configure = res.data.config
        that.gameList = res.data.gameList
        that.title = that.gameList[0].remark
        that.maxPeople = res.data.maxPeople
        that.minPeople = res.data.minPeople
        that.glist = that.gameList.filter(game => game.type === 0 && game.status == 1);
        if (that.configure.time) {
          that.mod = "time";
        }
      })
      if (this.ifdebug == 'true') {
        this.levleLock = 10;
      }
    },
    right() {
      if (this.tuan) {
        this.tuan = false
        this.backgroundUrl = this.right2;
        this.glist = this.gameList.filter(game => game.type === 1 && game.status == 1);
      } else {
        this.tuan = true
        this.backgroundUrl = this.right1;
        this.glist = this.gameList.filter(game => game.type === 0 && game.status == 1);
      }
      this.changegame(0);
      // this.gameInfo();
    },
    left() {

      // this.gameInfo();
    },
    changegame(i) {
      this.active = i

      if (this.glist[i]) {
        this.title = this.glist[i].remark
        this.getLock();
      }


    },

    changenum(i) {
      if (i <= this.levleLock) {
        this.numactive = i
      }
    },
    doTimer() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        }
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    back() {
      this.show = false;
      clearInterval(this.intervalId);
    },
    login() {
      if (this.users.length < this.minPeople) {
        this.$message({ message: '至少' + this.minPeople + '人开启游戏', type: 'error' });
        return false;
      }
      if (this.users.length > this.maxPeople) {
        this.$message({ message: '最多' + this.minPeople + '人开启游戏', type: 'error' });
        return false;
      }
      this.show = true;
      this.countdown = 60;
      this.startCountdown();
    },
    start() {
      if (this.users.length < this.minPeople) {
        this.$message({ message: '至少' + this.minPeople + '人开启游戏', type: 'error' });
        return false;
      }
      if (this.users.length > this.maxPeople) {
        this.$message({ message: '最多' + this.minPeople + '人开启游戏', type: 'error' });
        return false;
      }
      let game = this.glist[this.active];

      let game_name = game.name;
      // alert(game_name);
      let level = this.numactive.toString();
      let configure = this.configure
      let num = this.users.length;
      let game_configure = JSON.parse(game.config);
      let game_id = game_configure.id;
      let users = "";
      this.users.forEach(element => {
        users += element.id + ",";
      });
      document.location = `toapp://startGame?users=${users}&game_id=${game.gameId}&time=${this.time}&level=${level}&game_configure=${game.config}&game=${game_id}&num=${num}&configure=${configure}`
      // this.log({ "game_id": game_id, "game_name": game_name, "level": level, "game_status": "start", "is_finish": 0 });

    },//游戏结束	是否通过[0 1] over("1-1-1000")
    over(str) {
      const strs = str.split('-')
      let is_finish = strs[0];
      let level = strs[1];
      let point = strs[2];
      let game_id = this.gameList[this.active].game_id;
      let game_name = this.gameList[this.active].game_name;
      //如果过关同步积分
      if (is_finish) {
        this.point({ "game_id": game_id, "game_name": game_name, "level": level, point: point });
      }
      this.log({ "game_id": game_id, "game_name": game_name, "level": level, "game_status": "end", "is_finish": is_finish });
      this.reset();
      if (is_finish == '0') {
        document.location = `toapp://over`;
      }
    },//游戏日志
    log(data) {
      let that = this
      this.users.forEach(element => {
        that.$axios({
          method: "post",//指定请求方式
          url: "/api/set_game_log",//请求接口
          headers: { 'token': element.token },
          data: data
        })
      });
    },
    point(data) {
      let that = this
      this.users.forEach(element => {
        that.$axios({
          method: "post",//指定请求方式
          url: "/api/set_point_log",//请求接口
          headers: { 'token': element.token },
          data: data
        })
      });
    },
    getLock() {
      let level = 4; // 定义level变量，初始化为0
      const promises = this.users.map(element => {
        // 使用map来创建包含所有请求Promise的数组
        return this.$axios({
          method: "get",
          url: "/api/get_lock/" + element.id + "/" + JSON.parse(this.glist[this.active].config).id,
        }).then(res => {
          // console.log(res.data);
          if (res.data > level) {
            level = res.data; // 如果响应数据大于当前level，则更新level
          }
          if (element.isAdmin) {
            level = 10;
          }
        });
      });

      Promise.all(promises).then(() => {
        console.log(level);
        this.levleLock = level; // 所有请求完成后，设置levelLock为最终的level值
        if (this.ifdebug == 'true') {
          this.levleLock = 10;
        }
      })

    },
    reset() {
      this.active = 0
      this.numactive = 1
      this.ganeindex = 0
      this.list = [];
      this.users = [];
      document.location = `toapp://over`;
    },

    //读卡器读用户
    cardMessage(msg) {
      let that = this
      msg = msg.substring(msg.length - 10, msg.length - 2);
      msg = msg.substring(6, 8) + msg.substring(4, 6) + msg.substring(2, 4) + msg.substring(0, 2);
      msg = parseInt(msg, 16)
      let repeat = false;
      this.users.forEach(element => {
        if (element.cardNumber == msg) {
          repeat = true
          return;
        }
      });
      if (repeat) {
        return false;
      }
      that.cardRead(msg);
      // that.$axios({
      //   method: "get",//指定请求方式
      //   url: "/api/login/" + msg,//请求接口
      // }).then(function (res) {
      //   //刷卡登录
      //   if (that.users.length >= that.maxPeople) {
      //     that.$message({ message: '最多' + that.maxPeople + '人', type: 'error' });
      //     return false;
      //   }
      //   let user = res.data;
      //   // user.token = token;
      //   if (user.isAdmin) {
      //     user.leftTime = 999
      //     document.location = `toapp://open`;
      //   }
      //   let time = user.leftTime;
      //   if (time <= 0) {
      //     that.$message({ message: '游戏时间不足', type: 'error' });
      //     return false;
      //   }
      //   that.users.push(user);


      //   that.$message({ message: '玩家加入' + user.name, type: 'success' });

      //   that.list.push({ name: user.name, time: Math.floor(time / 60), points: user.points });
      //   that.getLock();//拉取锁
      //   document.location = `toapp://join?num=${that.list.length}`
      // if (token) {
      //   that.getUser(res.data.userId);

      // } else {
      //   that.$message({ message: '非法玩家', type: 'error' });
      // }
      // console.log(res);
      // }).catch(function (error) {
      //   that.$message({ message: error.response.data, type: 'error' });
      // })
    },
    cardRead(msg) {//回车输入的卡号
      let that = this
      let repeat = false;
      if (msg.length < 10) {
        that.$message({ message: '刷卡异常！', type: 'error' });
        return false;
      }
      this.users.forEach(element => {
        if (element.cardNumber == msg) {
          repeat = true
          return false;
        }
      });
      if (repeat) {
        return false;
      }
      if (!msg && msg == '') {
        that.$message({ message: '刷卡异常！', type: 'error' });
        return false;
      }
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/login/" + msg,//请求接口
      }).then(function (res) {

        //刷卡登录
        if (that.users.length >= that.maxPeople) {
          that.$message({ message: '最多' + that.maxPeople + '人', type: 'error' });
          return false;
        }
        let user = res.data;
        if (!user.id) {
          that.$message({ message: '用户异常！', type: 'error' });
          return false
        }
        // user.token = token;
        if (user.isAdmin) {
          user.leftTime = 6000
          document.location = `toapp://open`;
        }
        // user.token = token;
        let time = user.leftTime;
        if (time <= 0) {
          that.$message({ message: '游戏时间不足', type: 'error' });
          return false;
        }
        that.users.push(user);


        that.$message({ message: '玩家加入' + user.name, type: 'success' });

        that.list.push({ name: user.name, time: Math.floor(time / 3600) + ":" + Math.floor(time / 60) % 60 + ":" + Math.floor(time % 60), points: user.points });
        that.getLock();//拉取锁
        document.location = `toapp://join?num=${that.list.length}`
        // if (token) {
        //   that.getUser(res.data.userId);

        // } else {
        //   that.$message({ message: '非法玩家', type: 'error' });
        // }
        // console.log(res);
      }).catch(function (error) {
        console.log(error);
        // that.$message({ message: error, type: 'error' });
      })
    }
  }
}


</script>

<style scoped>
.all {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../assets/background.jpg");
  background-size: 100% 100%;
  display: flex;
  font-family: "pmzd";
  justify-content: center;
}

.all2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../assets/background3.jpg");
  background-size: 100% 100%;
  display: flex;
  font-family: "pmzd";
  justify-content: center;
}

.left {
  width: 45.5%;
  text-align: center;
}

.left_top {
  display: flex;
  justify-content: center;
  margin-top: 66px;
}

.left_top img {
  width: 61px;
  height: 70px;
  margin-top: 10px;
}

.top_tittle {
  width: 317px;
  line-height: 80px;
  height: 80px;
  font-size: 52px;
  padding: 5px 0px 0px 0px;
  border: 3px solid rgb(182, 220, 255);
  background: rgb(31, 76, 140);
  color: #fff;
  border-radius: 10px;
  letter-spacing: 0px;
  margin-right: 20px;
  margin-left: 20px;
}

.top_tittle2 {
  width: 317px;
  line-height: 80px;
  height: 80px;
  font-size: 52px;
  padding: 5px 0px 0px 0px;
  border: 3px solid #f7d1c4;
  background: #dc4510;
  color: #fff;
  border-radius: 10px;
  letter-spacing: 0px;
  margin-right: 20px;
  margin-left: 20px;
}

.left_bottom {
  border: 4px solid rgb(182, 220, 255);
  border-radius: 10px;
  margin-top: 52px;
  background-image: url("../assets/backgame.png");
  background-size: 100% 550px;
  background-repeat: no-repeat;
}

.left_bottom2 {
  border: 4px solid #dc450e;
  border-radius: 10px;
  margin-top: 52px;
  background-size: 100% 550px;
  background-repeat: no-repeat;
  width: 50%;
  height: 76.5%;
}

.bottom_title {
  font-family: "pmzd";
  font-size: 42px;
  color: #fff;
  margin-top: 28px;
}

.bottom_allcard {
  height: 216px;
  overflow: auto;
  margin-top: 20px;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bottom_allcard::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.bottom_card {
  width: calc(50% - 50px);
  border: 4px solid rgb(182, 220, 255);
  margin-right: 21px;
  margin-left: 21px;
  border-radius: 10px;
  height: 44px;
  line-height: 44px;
  margin-bottom: 20px;
  font-size: 31px;
  color: #fff;
}

.bottom_card_active {
  background: rgb(39, 101, 179);
  width: calc(50% - 50px);
  border: 4px solid rgb(182, 220, 255);
  margin-right: 21px;
  margin-left: 21px;
  border-radius: 10px;
  height: 43px;
  line-height: 43px;
  margin-bottom: 20px;
  font-size: 31px;
  color: #fff;
}

.bottom_card2 {
  width: calc(100% - 50px);
  border: 4px solid #dc450e;
  margin-right: 21px;
  margin-left: 21px;
  border-radius: 10px;
  height: 44px;
  line-height: 44px;
  margin-bottom: 20px;
  font-size: 31px;
  color: #fff;
}

.bottom_card_active2 {
  background: #f24609;
  width: calc(100% - 50px);
  border: 4px solid #dc450e;
  margin-right: 21px;
  margin-left: 21px;
  border-radius: 10px;
  height: 43px;
  line-height: 43px;
  margin-bottom: 20px;
  font-size: 31px;
  color: #fff;
}


.gameall {
  position: relative;
}

.bottom_input {
  width: calc(100% - 71px);
  height: 133px;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  border: none;
  padding: 16px 12px 11px 31px;
  margin: 22px 10px 0px 10px;
  color: #fff;
  resize: none;
  font-size: 19px;
  letter-spacing: 2px;
  line-height: 25px;
  text-align: left;
  font-family: 'SimHei';
}

.bottom_input2 {
  height: 170px;
  overflow: auto;
  border: none;
  padding: 16px 12px 11px 11px;
  margin: 22px 10px 0px 51px;
  color: #fff;
  resize: none;
  font-size: 19px;
  letter-spacing: 2px;
  line-height: 25px;
  text-align: left;
  font-family: 'SimHei';
}


.bottom_input::-webkit-scrollbar {

  width: 0px;

  height: 0px;

}

.game {
  position: absolute;
  bottom: 12px;
  right: 32px;
  font-size: 26px;
  letter-spacing: 3px;
  color: #fff;
}

.bottom_level {
  font-size: 31px;
  color: #ffffff;
  margin-top: 50px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.bottom_num {
  display: flex;
  flex-wrap: wrap;
  padding-left: 32px;
  margin-bottom: 42px;
}

.num {
  width: 44px;
  height: 43px;
  border: 4px solid rgb(182, 220, 255);
  margin: 0px 25px 16px 25px;
  color: #fff;
  font-size: 26px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: "hyt"; */
}

.num_active {

  background: rgb(39, 101, 179);
  width: 44px;
  height: 43px;
  border: 4px solid rgb(182, 220, 255);
  margin: 0px 25px 10px 25px;
  color: #fff;
  font-size: 26px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: "hyt"; */
}

.right {
  text-align: center;
}

.right2 {
  margin-top: 147px;
  text-align: center;
  position: absolute;
  right: 100px;
  width: 848px;
}

.right_top2 {
  width: 840px;
  height: 550px;
  border: 4px solid #dc450e;
  border-radius: 10px;
  margin-top: 62px;
  margin-left: 51px;
  background-size: 100% 550px;
  background-repeat: no-repeat;
  padding-bottom: 5px;
}

.right_top {
  width: 573px;
  height: 550px;
  border: 4px solid rgb(182, 220, 255);
  border-radius: 10px;
  margin-top: 62px;
  margin-left: 51px;
  background-image: url("../assets/backgame.png");
  background-size: 100% 550px;
  background-repeat: no-repeat;
  padding-bottom: 5px;
}

.right_top_title {
  display: flex;
  font-size: 37px;
  margin-left: 17px;
}

.right_top_title .title {
  width: 50%;
  text-align: center;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 10px;
}

.right_top_card_all {
  height: 370px;
  overflow-y: auto;
}

.right_top_card_all::-webkit-scrollbar {

  width: 0px;

  height: 0px;

}

.right_top_card {
  display: flex;
  margin-left: 17px;
}

.right_top_card_right {
  width: 280px;
  border: 4px solid rgb(182, 220, 255);
  margin-right: 30px;
  border-radius: 10px;
  height: 56px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 29px;
  color: #fff;
}

.right_top_card_left {
  width: 204px;
  border: 4px solid rgb(182, 220, 255);
  margin-right: 17px;
  border-radius: 10px;
  height: 56px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 26px;
  color: #fff;
}

.right_top_card_right_hover {
  background: rgb(39, 101, 179);
  width: 280px;
  border: 4px solid rgb(182, 220, 255);
  margin-right: 30px;
  border-radius: 10px;
  height: 56px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 29px;
  color: #fff;
}
.right_top_card_right_hover0 {
  border: 4px solid #ff0808;
  background: linear-gradient(to top, #ff0505, rgba(255, 0, 0, 0));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 289px;
  margin-right: 30px;
  border-radius: 10px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 29px;
  color: #fff;
}
.right_top_card_right_hover1 {
  border: 4px solid #12ff00;
  background: linear-gradient(to top, #22ff17, rgba(0, 128, 0, 0)); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 280px;
  margin-right: 30px;
  border-radius: 10px;
  height: 56px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 29px;
  color: #fff;
}
.right_top_card_right_hover2 {
  border: 4px solid #3c00ff;

  background: linear-gradient(to top, #450cff, rgba(0, 0, 255, 0));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 289px;
  margin-right: 30px;
  border-radius: 10px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 29px;
  color: #fff;
}
.right_top_card_right_hover3 {
  border: 4px solid #fffc00;

  background: linear-gradient(to top, #fffc08, rgba(255, 255, 0, 0)); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 289px;
  margin-right: 30px;
  border-radius: 10px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 29px;
  color: #fff;
}
.right_top_card_right_hover4 {
  border: 4px solid #ff00f0;
  border-radius: 10px;
  background: linear-gradient(to top, #ff08f0, rgba(128, 0, 128, 0));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 289px;
  margin-right: 30px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 29px;
  color: #fff;
}

.right_top_card_left_hover {
  
  background: rgb(39, 101, 179);
  width: 204px;
  border: 4px solid rgb(182, 220, 255);
  margin-right: 17px;
  border-radius: 10px;
  height: 56px;
  line-height: 56px;
  margin-bottom: 10px;
  font-size: 26px;
  color: #fff;
}

.reset {
  width: 209px;
  height: 49px;
  background-image: url("../assets/reset.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 36px;
  color: #ffffff;
  line-height: 49px;
  margin: 96px 0px 39px 227px;
  letter-spacing: 3px;
}

.start {
  width: 450px;
  height: 163px;
  background-image: url("../assets/start.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 85px;
  color: #ffffff;
  line-height: 173px;
  letter-spacing: 10px;
  margin: 33px 0px 0px 110px;
}
.reset2 {
  margin-left: 33px;
  width: 385px;
  height: 83px;
  background-image: url("../assets/reset2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
 
}

.start2 {
  margin-left: 33px;
  width: 385px;
  height: 168px;
  background-image: url("../assets/start3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
